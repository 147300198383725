.main {
    height: 100%;
    width: 100%;
    background-image: url("../../images/login.png");
    background-size: cover;
    background-position-y: center;
    display: grid;
    grid-template-rows: 0.1fr 1fr 0.3fr;

    & .header-login {
        height: 60px;
        width: 100%;
        background-color: rgba(#010828, 0.7);

        h3 {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: flex-end;
            color: var(--primary-white-text);
            padding: 0% 5% 0% 0%;
            margin: 0;
        }
    }

    & .footer-login {
        display: grid;
        grid-template-columns: 0.4fr 1fr;
        height: 100%;
        width: 100%;
        background-color: rgba(#010828, 0.7);
        color: var(--primary-white-text);


        & .info {
            display: grid;
            grid-template-rows: 0.5fr 1fr;
            align-items: center;
            margin: 10px 0px 10px 20%;

            & h3 {
                margin: 0;
                font-size: 35px;
            }

            & h4 {
                margin: 0;
            }

            border-right: 1px solid rgba(white, 0.4);
        }

        & .description {
            display: grid;
            align-items: center;

            & p {
                margin: 0 5%;
            }
        }
    }

    & .container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;
        gap: 50px;

        & form {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 30px;
            width: 25%;
            background-color: rgba(white, 0.7);
            border: 2px solid var(--primary-blue);
            border-radius: 10px;
            margin: 0px 0px 0px 5%;

            & h3 {
                margin: 0;
            }

            & .logo {
                margin: 0 auto;
                height: 100px;
                width: 60%;
                background-image: url("../../images/inline-logo.png");
                background-size: contain;
                background-repeat: no-repeat;
            }

            & hr {
                margin: 0;
                border: 1px solid rgba(#010828, 0.4);
                width: 100%;
            }

            & input {
                height: 36px;
                border-color: var(--primary-gold);
                border-radius: 18px;
                background-color: transparent;
                padding: 0px 0px 0px 10px;
            }

            & .input {
                padding: 10px 0px 0px 0px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                & label {
                    font-size: 14px;
                }
            }

            & #buttons {
                display: flex;
                flex-direction: column;
                gap: 20px;
                padding: 0px 0px 0px 0px;

                & #button {
                    background-color: var(--primary-blue);
                    color: var(--primary-white);
                    border-radius: 30px;
                    width: 100%;
                }

                & .googleBtn{
                    display: flex;
                    width: 100%;
                    background: #4285F4;
                    font-size: 16px;
                    border-radius: 16px;
                    color: #fff;
                    height: 32px;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        & img {
            height: 200px;
        }
    }
}

@media (max-width: 1100px) {
    .main {
        background-position-x: center;

        & .container {
            justify-content: center;

            & form {
                margin: 0;
                width: 40%;
            }
        }

        & .footer-login {
            grid-template-columns: 0.7fr 1fr;

            & .info {
                margin: 0px 0px 0px 5%;
            }
        }
    }
}

@media (max-width: 760px) {
    .main {
        background-attachment: fixed;
        & .container {

            & form {
                width: 80%;
            }
        }

        & .footer-login {

            & .info {

                & h3 {
                    font-size: 25px;
                }

                & h4 {
                    font-size: 20px;
                }
            }
        }
    }
}